import { FC, useState } from 'react';
import { Title } from '../../../title';
import styles from './index.module.scss';
import { Check } from '../../../icons/check';
import { Button } from '../../../button';
import { goTo } from '../../../../helpers/goTo';
import { Tariffs } from '../../../tariffs';
import Tooltip from '../../../tooltip';


export const Mission: FC = () => {
	const [isAllTariffsModalOpened, setIsAllTariffsModalOpened] = useState(false);
	const missions = [
		{
			title: 'Естественная конкуренция',
			text: (
				<span className={styles.description}>
					Продавец сам определяет что продавать и за какую цену, а значит ищет самые востребованные товары
				</span>
			),
		},
		{
			title: 'Без рисков неопределённости',
			text: <span className={styles.description}>Продавец точно и всегда знает сумму затрат по каждому товару</span>,
		},
		{
			title: 'Выгодно продавцу',
			text: (
				<span className={styles.description}>
					Emall даёт удобную витрину для продажи товаров за небольшое <Tooltip
						id="id_1222"
						message="4% от продаж вне зависимости от категории и стоимости товара"
					><span className={styles.link}>вознаграждение</span></Tooltip>, доставляет и выдаёт
					товары покупателю по низким <span onClick={() => setIsAllTariffsModalOpened(true)} className={styles.link}>тарифам</span>
				</span>
			),
		},
		{
			title: 'Как результат - низкие цены для покупателя',
		},
		{
			title: 'Оперативное управление ассортиментом и заказами',
			text: <span className={styles.description}>Автоматизируйте работу вашего магазина с помощью API</span>,
		},
		{
			title: 'Еще выгоднее покупателю',
			text: <span className={styles.description}>Приобретение товаров в рассрочку на привлекательных условиях</span>,
		},
	];

	return (
		<section className={styles.mission}>

			{isAllTariffsModalOpened && <Tariffs onClose={() => setIsAllTariffsModalOpened(false)} />}
			<figure className={styles.top}>
				<figcaption className={styles.left}>
					<Title title="Наша миссия" />
					<p className={styles.text}>
						Создать местный белорусский маркетплейс, организовав процессы так, чтобы покупатель мог здесь найти широкий
						ассортимент товаров по низким ценам, а продавец мог выгодно и комфортно вести продажи своих товаров
					</p>
				</figcaption>
				<img src="/assets/images/busel-mission.png" alt="Наша миссия" className={styles.image} />
			</figure>
			<div className={styles.bottom}>
				{missions.map(({ title, text }, index) => (
					<div key={index} className={styles.item}>
						<span className={styles.title}>
							<Check className={styles.check} />
							{title}
						</span>
						{text && text}
					</div>
				))}
			</div>
			<Button
				onClick={goTo.registration}
				text="Стать продавцом"
				type="primary"
				size="large"
				className={styles.button}
			/>
		</section>
	);
};
